.ta-card {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  padding: 15px;
  user-select: none;
  cursor: pointer;
}
.main-widgets {
  .ta-card {
    height: 100%;
    &.ta-card-loading {
      position: relative;
      height: 400px;
      padding: 0;
    }
  }
}
.dependent-widgets {
  .ta-card {
    height: 400px;
    &.ta-card-loading {
      position: relative;
      height: 400px;
      padding: 0;
    }
  }
}
.ta-selected {
  border-bottom: 8px solid #00a0af;
  position: relative;
  width: 100%;
  bottom: 8px;
  .arrow {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #00a0af;
  }
}
