.diy-loading {
  background-color: #e6e6e6;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    '. . .'
    '. loadText .'
    '. . .';

  .diy-spinner {
    background-color: #ffffff;
    border-radius: 50%;
    padding: 10px;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    box-sizing: content-box;

    &::after {
      content: ' ';
      display: inline-block;
      border: 2px solid #2892c0;
      position: absolute;
      top: 8px;
      bottom: 8px;
      left: 8px;
      right: 8px;
      border-radius: 50%;
      border-top-color: transparent;
      animation: spin 1s infinite;
      -webkit-animation: spin 1s infinite;
      margin: 0;
    }
  }

  .diy-text {
    text-align: center;
    justify-self: center;
    align-self: start;
    text-wrap: wrap;
    grid-area: loadText;
    width: 260px;
  }
}

.diy-loading-skeleton {
  .diy-loading-skeleton-item {
    margin: 1rem;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
