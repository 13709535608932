.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto', sans-serif;
  background-color: white;
  padding: 20px;
  z-index: 10000;
  font-family: 'Roboto', sans-serif;
}

.custom-overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.diy-calendar-buttons {
  float: right;
  margin-top: 15px;
}

.diy-calendar-buttons button {
  width: 150px;
}

.diy-calendar-btn-cancel {
  margin-left: 40px;
}

.columnCalendarContainer {
  flex: 1;
}

.dropDownDateRange {
  width: 200px;
}

.dateRangeSelection {
  width: 165px;
  padding: 74px 0px 2px 0px;
}

.dateRangeSelectionOption {
  list-style-type: none;
  color: #00a0af;
  padding: 2px 0px 2px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

/* ul li:hover {
  background-color: #d1f0ee;
} */

ul li a {
  text-decoration: none;
  color: inherit;
}

.react-calendar {
  border: none;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  width: 480px;
}

.dateRangeContainer {
  border: 1px solid #cacdd4;
  margin: 5px;
  padding: 10px;
  font-size: 12px;
}

.react-calendar__tile--now {
  background-color: white;
  color: rgb(255, 255, 255);
}

.react-calendar__tile--now:hover {
  background-color: rgb(0, 160, 175);
  color: rgb(204, 204, 204);
}

.react-calendar__tile--active {
  background-color: #d1f0ee;
  color: rgb(204, 204, 204);
  border: 1px solid #d1f0ee;
}

.react-calendar__month-view__days__day--weekend {
  color: rgb(204, 204, 204);
}

.react-calendar__tile--range {
  background-color: rgb(102, 196, 204);
  color: rgb(204, 204, 204);
}

.react-calendar__tile--rangeEnd {
  background-color: rgb(0, 160, 175);
  color: rgb(204, 204, 204);
}

.react-calendar__tile--rangeStart {
  background-color: rgb(0, 160, 175);
  color: rgb(204, 204, 204);
}

button.react-calendar__tile:hover {
  background-color: rgb(0, 160, 175) !important;
  color: rgb(255, 255, 255);
}

/*set opacity for neighboringMonth*/
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth.diyCalendarTile {
  opacity: 0.25;
  color: rgb(204, 204, 204);
}

.react-calendar button {
  font-size: 12px;
  font-family: Roboto;
  font-weight: 300;
  border-radius: 0px;
  margin: 0px;
  width: 25px;
  color: rgb(204, 204, 204);
}

.react-calendar__navigation__label__labelText--from {
  padding-right: 66px;
  color: #000;
}

.react-calendar__navigation__label__divider {
  padding-right: 70px;
  color: #000;
}

.react-calendar__navigation__label__labelText--to {
  color: #000;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: rgb(0, 160, 175);
}
